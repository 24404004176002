import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';
import PhotoUpload from '../../../shared/PhotoUpload';
import CompanyLogoUpload from '../../../shared/CompanyLogoUpload';

const Profile = ({ id }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Heading id={id} />

      <h1>
        Profile section is common for both the features: Resume & Business Card
      </h1>

      <Input
        name="heading"
        label={t('builder.sections.heading')}
        path={`${id}.heading`}
      />

      <PhotoUpload />

      <div className="grid grid-cols-2 gap-6">
        <Input
          name="firstName"
          label={t('builder.profile.firstName')}
          path="profile.firstName"
        />
        <Input
          name="lastName"
          label={t('builder.profile.lastName')}
          path="profile.lastName"
        />
      </div>

      <CompanyLogoUpload />

      <Input
        name="companywebsite"
        label="Company website"
        path="profile.companywebsite"
      />

      <Input
        name="subtitle"
        label={t('shared.forms.subtitle')}
        path="profile.subtitle"
      />

      <Input
        type="date"
        name="birthDate"
        label={t('builder.profile.birthDate')}
        path="profile.birthDate"
      />

      <hr />

      <Input
        name="addressLine1"
        label={t('builder.profile.address.line1')}
        path="profile.address.line1"
      />
      <Input
        name="addressLine2"
        label={t('builder.profile.address.line2')}
        path="profile.address.line2"
      />

      <div className="grid grid-cols-2 gap-6">
        <Input
          name="city"
          label={t('builder.profile.address.city')}
          path="profile.address.city"
        />
        <Input
          name="pincode"
          label={t('builder.profile.address.pincode')}
          path="profile.address.pincode"
        />
      </div>

      <hr />

      <Input
        name="phone"
        label={t('shared.forms.phone')}
        path="profile.phone"
      />
      <Input
        name="website"
        label={t('shared.forms.website')}
        path="profile.website"
      />
      <Input
        name="email"
        label={t('shared.forms.email')}
        path="profile.email"
      />

      <hr />

      <h1>This section is for business card only</h1>

      <Input
        name="facebook"
        label="Facebook Username"
        path="profile.facebook"
      />
      <Input name="twitter" label="Twitter Username" path="profile.twitter" />
      <Input
        name="whatsapp"
        label="WhatsApp (enter your country code and number without space and `+` symbol) ex. 919769722287"
        path="profile.whatsapp"
      />
      <Input
        name="youtube"
        label="YouTube Channel Link"
        path="profile.youtube"
      />
      <Input name="linkedin" label="LinkedIn URL" path="profile.linkedin" />
    </section>
  );
};

export default memo(Profile);
